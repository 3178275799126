(($) ->
  if $('.controller-live_feeds.action-index').length == 1

    #setTimeout 'location.reload(true);', 10000

    $('#live-feeds-multiple-destroy').on 'click', (event) ->
      event.preventDefault()
      console.log('lol')
      confirm_destroy_text = if $('.multiple-ckbox-live-feeds:checked').length > 0
        $(@).data('text-many')
      else
        $(@).data('text-one')
      return false if $(@).hasClass('disabled') || !confirm(confirm_destroy_text)
      console.log('lol2')
      params = ''
      $('.multiple-ckbox-live-feeds:checked').each ->
        params += '&ids[]='+$(@).val()
      window.location.href = $(@).attr('href')+params
)(jQuery)
$(window).on "load", ->

 if $('#alert-child-categories option').length >= 1
    displaySubCategories('#alert-parent-categories')


 if $('.neighborhoods-container').children().length == 0
   $('.neighborhoods-container').append($('.users-container'))


$('#alert-parent-categories').change ->
  $("#alert-child-categories").select2('val', '')
  displaySubCategories($(this))



#  return child_categories_alerts_categories according to the selected parent_category
displaySubCategories = (id) ->
  url =  '/admin/categories/' + $('#alert-parent-categories').val() + '?feature_id=' +  $('#alert-show-form').attr('class')
  request = undefined
  request = $.ajax(url: url)
  request.done (data, textStatus, jqXHR) ->
    $('#alert-child-categories option').remove()
    if data.child_categories? && data.child_categories.length > 0
      $("#alert-child-categories").append('<option value="">Aucune</option>')
      data.child_categories.forEach (child_category) ->
        $("#alert-child-categories").append('<option value=' + child_category.id + '>' + child_category.name + '</option>')


$('.alerts-filter').submit ->
  startingDateString = $('#starting_date_').val().split("/")
  endingDateString   = $('#ending_date_').val().split("/")
  startingDate =  new Date(parseInt(startingDateString[2]),parseInt(startingDateString[1]),parseInt(startingDateString[0]))
  endingDate   =  new Date(parseInt(endingDateString[2]),parseInt(endingDateString[1]), parseInt(endingDateString[0]))
  if  (startingDate  >  endingDate) == true
    alert("La date de fin de recherche ne peut être antérieure à la date de début de recherche")
    return false
  



(($) ->
  $(document).on 'click', '.remove_fields', (event) ->
    event.preventDefault()
    $(@).prev('input[type=hidden]').val('1')
    $fieldset = $(@).closest('fieldset')
    $fieldset.hide()
    fields_index = 1
    $fieldset.parent().find('fieldset:visible').each ->
      $(@).find('.nested_index').html(fields_index)
      fields_index += 1
    window[$(@).data('callback')].apply(null, [$fieldset]) unless $(@).data('callback') == null || $(@).data('callback') == undefined


  $(document).on 'click', '.add_fields', (event) ->
    time = new Date().getTime()
    regexp = new RegExp($(@).data('id'), 'g')
    fields_index = $(@).parent().find('> fieldset:visible').length + 1
    $fields = $($(@).data('fields').replace(regexp, time))
    $fields.find('.nested_index').html(fields_index)
    $(@).before($fields[0])
    $fields.find('.select2').select2()
    # callback with fieldset as params
    $fieldset = $('.add_fields').last().prev()
    window[$(@).data('callback')].apply(null, [$fieldset]) unless $(@).data('callback') == null || $(@).data('callback') == undefined
    event.preventDefault()
)(jQuery)

(($) ->
  settings_module =
    init: ->
      if $('.controller-settings.action-index').length == 1
        settings_module.switch_fields()

    switch_fields: ->
      $('.module-enabled').each ->
        $input = $(@).find('.switch-input')
        $toggle = $(@).find('.toggle')
        $toggle.toggles
          on: $input.val() == 'true',
          click: true,
          drag: true,
          text:
            on: 'ACTIVÉ'
            off: 'DÉSACTIVÉ'
        $toggle.on 'toggle', (e, active) ->
          if active
            $input.val('true')
          else
            $input.val('false')


  $(document).ready ->
    settings_module.init()
)(jQuery)
$('.category-trash').click ->
  deleteCategoryUrl =  $(this).attr("id")
  getAlertsToUpdate deleteCategoryUrl

getAlertsToUpdate = (deleteCategoryUrl) ->
  request = undefined
  request = $.ajax(url: deleteCategoryUrl, type: 'delete')
  request.done (data, textStatus, jqXHR) ->
    if data.status == 200
      location.reload()
    else
      categoriesToRemove = data.categories_to_remove
      attributesId = []
      featureId = []
      if data.parent_category_attributes.length > 0 && data.child_categories_attributes.length > 0
        data.child_categories_attributes.forEach (child_category_attribute) ->
          attributesId.push(child_category_attribute.id)
        data.parent_category_attributes.forEach (attribute) ->
          attributesId.push(attribute.id)
      else if data.child_categories_attributes.length > 0
        data.child_categories_attributes.forEach (child_category_attribute) ->
          attributesId.push(child_category_attribute.id)
      else if data.parent_category_attributes.length > 0
        data.parent_category_attributes.forEach (attribute) ->
          attributesId.push(attribute.id)
      else
        return false
      featureId = data.feature_id
      hasAlternativeCategories = data.has_alternative_categories
      if hasAlternativeCategories == false
        alert('Vous ne pouvez pas supprimer cette catégorie')
        return
      updateAlerts attributesId, featureId, deleteCategoryUrl, categoriesToRemove, data

# Update alerts category_id that are going to be deleted with new category_id
updateAlerts = (attributesId , featureId,  deleteCategoryUrl, categoriesToRemove, data) ->
  attributesToUpdateCount = data.parent_category_attributes.length +  data.child_categories_attributes.length
  categoriesToRemove.forEach (categoryToRemove) ->
    $('#_id option[value=' + categoryToRemove + ']').remove()
    $("#_id").trigger("change")
  $('#category-modal').modal('show')
  $( ".p" ).text( attributesToUpdateCount +  ' alertes appartiennent à la catégorie que vous souhaitez supprimer. Merci de choisir une nouvelle catégorie pour les alertes de la catégorie avant de la supprimer.' )
  $('#category-modal').on 'hidden.bs.modal', ->
    location.reload()
  $('#form-no-submit').submit ->
    newCategoryId = $('#_id').select2('data')[0].id
    request = undefined
    attributesCount = attributesId.length
    attributesUpdated = 0
    attributesId.forEach (attributeId) ->
      url = '/admin/alerts/' + attributeId + '?feature_id=' + featureId
      valueToSubmit =  { "alert": {"category_id": newCategoryId } }
      request = $.ajax(url: url, type: 'PATCH', data:  valueToSubmit)
      request.done (data, textStatus, jqXHR) ->
        attributesUpdated++
        if attributesUpdated ==  attributesCount
          setTimeout(-> deleteCategories (deleteCategoryUrl), 1000)
    return false
  return false


# Once dependent alerts are updated request to Category#delete to delete selected categories
deleteCategories = (deleteCategoryUrl) ->
  request = undefined
  request = $.ajax(
    url: deleteCategoryUrl,
    type: 'delete')
  request.done (data, textStatus, jqXHR) ->
    if data.status == 200
      location.reload()
      false
    else
      location.reload()
      alert 'Catégorie non mise à jour'
      false
(($) ->
  infos_module =
    init: ->
      if $('.controller-infos.action-city, .controller-infos.action-update_city').length == 1
        infos_module.gmaps()
      else if $('.controller-infos.action-new, .controller-infos.action-create, .controller-infos.action-edit, .controller-infos.action-update').length == 1
        infos_module.timepicker_fields()
        infos_module.period_toggle_fields()


    timepicker_fields: ->
      # $(document).on 'click focus', '.timepicker', (event) ->
      #   $(@).timepicker
      #     showMeridian: false
      #     showSeconds: false
      #     defaultTime: false
      #     showInputs: false
      load_timepicker = (item) ->
        return if $(item).hasClass('has_timepicker')
        $(item).addClass('has_timepicker')
        $(item).timepicker
          showMeridian: false
          showSeconds: false
          defaultTime: false
        set_timepicker_default_time(item)

      set_timepicker_default_time = (item) ->
        value = $(item).val() # fixes weird bug where $(item).val() is empty if called from setTimeout
        unless value == ''
          set_time = ->
            $(item).timepicker('setTime', value)
          setTimeout set_time, 10

      $('.timepicker').each ->
        load_timepicker(@)


    gmaps: ->
      map = new GMaps
        el: '#city_gmaps'
        lat: $('#city_gmaps').data('markerLat')
        lng: $('#city_gmaps').data('markerLng')
        zoom: 8
      if $('#city_gmaps').data('markerLat') != null
        map.addMarker
          lat: $('#city_gmaps').data('markerLat')
          lng: $('#city_gmaps').data('markerLng')

      geocode_search = ->
        GMaps.geocode
          address: $('#city_search').val() || 'Paris'
          callback: (results, status) ->
            if status == 'OK'
              latlng = results[0].geometry.location
              map.setCenter(latlng.lat(), latlng.lng())
              map.removeMarkers
              map.addMarker
                lat: latlng.lat()
                lng: latlng.lng()
              $('#setting_town_hall_coordinates').val(latlng.lat()+', '+latlng.lng())

      $('#city_search').on 'keypress', (event) ->
        if event.which == 13
          event.preventDefault()
          geocode_search()

      $('#city_geocoding').on 'click', ->
        geocode_search()
        event.preventDefault()


    period_toggle_fields: ->
      enable_timepickers = ($timepickers) ->
        $timepickers.removeAttr('disabled')
        $timepickers.timepicker('setTime', '')
        $timepickers.val('')
        $timepickers.attr('placeholder', '')
        $timepickers.removeClass('text-center')

      disable_timepickers = ($timepickers) ->
        $timepickers.attr('disabled', 'disabled')
        $timepickers.addClass('text-center')
        $timepickers.timepicker('setTime', '')
        $timepickers.val('')
        $timepickers.attr('placeholder', "–– : ––")

      $('.day-fields').each ->
        $input = $(@).find('.closed_hidden_field')
        $toggle = $(@).find('.toggle')
        $timepickers = $(@).find('.timepicker')

        if $input.val() == '0'
          enable_timepickers($timepickers)
        else
          disable_timepickers($timepickers)

        $toggle.toggles
          on: $input.val() == '0',
          click: true,
          drag: true,
          text:
            on: 'OUVERT'
            off: 'FERMÉ'
        $toggle.on 'toggle', (e, active) ->
          if active
            $input.val('0')
            enable_timepickers($timepickers)
          else
            $input.val('1')
            disable_timepickers($timepickers)



  $(document).ready ->
    infos_module.init()

)(jQuery)
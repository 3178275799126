(($) ->
  $(document).ready ->
    $('.toggle.toggle-yesno').each ->
      $input = $(@).next('input[type=hidden]')
      $toggle = $(@)
      if $input.is(":disabled")
        $toggle.addClass('disabled')
      $toggle.toggles
        on: $input.val() == 'true' || $input.val() == '1'
        click: !$toggle.hasClass('disabled')
        drag: !$toggle.hasClass('disabled')
        text:
          on: 'Oui'
          off: 'Non'
      $toggle.on 'toggle', (e, active) ->
        if active
          $input.val('true')
        else
          $input.val('false')
)(jQuery)

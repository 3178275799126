const images = require.context('../images', true)
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'jquery'
import 'bootstrap/dist/js/bootstrap'
import 'moment'
import 'select2/dist/js/select2.full.min.js'
import 'bootstrap-datetimepicker-4.17.37'
import 'eonasdan-bootstrap-datetimepicker' 
import 'trix'
import 'chartkick'
import 'chart.js'
import 'jquery-ui'
import 'jquery-ujs'
import 'jquery-ui-bundle'
import 'underscore'
import 'gritter/js/jquery.gritter.js'

import '../src/colorpicker/js/colorpicker.js'

import "../stylesheets/application.scss"
import '../src/jquery-toggles/toggles.js'
import '../src/admin/admins.js.coffee'
import '../src/admin/alerts.js.coffee'
import '../src/admin/analytics.js.coffee'
import '../src/admin/attachments.js'
import '../src/admin/category.js.coffee'
import '../src/admin/colorpicker.js.coffee'
import '../src/admin/dashboard.js.coffee'
import '../src/admin/dataTable.js.coffee'
import '../src/admin/datepicker.js.coffee'
import '../src/admin/discussion_comment.js.coffee'
import '../src/admin/discussion_document.js.coffee'
import '../src/admin/discussion_theme.js.coffee'
import '../src/admin/discussion_topic.js.coffee'
import '../src/admin/dynamic_fields.js.coffee'
import '../src/admin/groups.js.coffee'
import '../src/admin/infos.js.coffee'
import '../src/admin/job_offers.js.coffee'
import '../src/admin/live_feeds.js.coffee'
import '../src/admin/locations.js.coffee'
import '../src/admin/misc.js.coffee'
import '../src/admin/news.js.coffee'
import '../src/admin/per_page.js.coffee'
import '../src/admin/select2.js.coffee'
import '../src/admin/settings.js.coffee'
import '../src/admin/sortable-list.js.coffee'
import '../src/admin/survey_sub_charts.js.coffee'
import '../src/admin/surveys.js.coffee'
import '../src/admin/toggle.js.coffee'
import '../src/admin/users.js.coffee'


import '../src/bracket/fileinput'

import '../src/bracket/custom'

import '../src/maps.js.coffee'
window.jQuery = $;
window.$ = $;

(($) ->
  locations_module =
    init: ->
      if $('.controller-locations.action-new, .controller-locations.action-create, .controller-locations.action-edit, .controller-locations.action-update').length == 1
        locations_module.datepicker_fields()
        locations_module.timepicker_fields()
        locations_module.period_toggle_fields()


    datepicker_fields: ->
      $(document).on 'click focus', '.datepicker', (event) ->
        unless $(@).hasClass('hasDatepicker')
          $alt = $(@).next('input[type=hidden]')
          alt_id = $alt.attr('id')
          $(@).datepicker
            dateFormat: 'dd/mm/yy'
            altField: '#'+alt_id
            altFormat: 'yy-mm-dd'
            setDate: $alt.val()
            autoclose: true


    timepicker_fields: ->
      # also called from "apply_toggle_fields" callback
      load_timepicker = (item) ->
        return if $(item).hasClass('has_timepicker')
        $(item).addClass('has_timepicker')
        $(item).timepicker
          showMeridian: false
          showSeconds: false
          defaultTime: false
        set_timepicker_default_time(item)

      set_timepicker_default_time = (item) ->
        value = $(item).val() # fixes weird bug where $(item).val() is empty if called from setTimeout
        unless value == ''
          set_time = ->
            $(item).timepicker('setTime', value)
          setTimeout set_time, 10

      $(document).on 'click focus', '.timepicker', (event) ->
        load_timepicker(@)

      $('.timepicker').each ->
        load_timepicker(@)

    period_toggle_fields: ->
      $('fieldset.period_fields').each ->
        window.apply_toggle_fields $(@)


  $(document).ready ->
    locations_module.init()


  window.apply_toggle_fields = ($fieldset) ->
    enable_timepickers = ($timepickers) ->
      $timepickers.removeAttr('disabled')
      $timepickers.timepicker('setTime', '')
      $timepickers.val('')
      $timepickers.attr('placeholder', '')
      $timepickers.removeClass('text-center')

    disable_timepickers = ($timepickers) ->
      $timepickers.attr('disabled', 'disabled')
      $timepickers.addClass('text-center')
      $timepickers.timepicker('setTime', '')
      $timepickers.val('')
      $timepickers.attr('placeholder', "–– : ––")

    # init new timepicker fields
    locations_module.timepicker_fields()

    $fieldset.find('.day-fields').each ->
      $input = $(@).find('.closed_hidden_field')
      $toggle = $(@).find('.toggle')
      $timepickers = $(@).find('.timepicker')

      if $input.val() == '0'
        enable_timepickers($timepickers)
      else
        disable_timepickers($timepickers)

      $toggle.toggles
        on: $input.val() == '0',
        click: true,
        drag: true,
        text:
          on: 'OUVERT'
          off: 'FERMÉ'
      $toggle.on 'toggle', (e, active) ->
        if active
          $input.val('0')
          enable_timepickers($timepickers)
        else
          $input.val('1')
          disable_timepickers($timepickers)
)(jQuery)
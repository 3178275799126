moment = require('moment')
datetimepicker = require('eonasdan-bootstrap-datetimepicker')
(($) ->
  $(document).ready ->
    $('.datetimepicker').each(->
      $this = $(this)
      $altField = $('<input type="hidden" />').attr('name', $this.attr('name')).val($this.val())
      $this.datetimepicker(
        format: 'DD/MM/YYYY HH:mm',
        locale: moment.locale(),
        sideBySide: $this.data('sideBySide') || false,
        extraFormats: [moment.ISO_8601]
      ).on 'dp.change', (e) ->
        $altField.val e.date.toJSON()
      $(this).after($altField)
    )

    $('.datepicker').each(->
      $this = $(this)
      $altField = $('<input type="hidden" />').attr('name', $this.attr('name')).val($this.val())
      $(this).datetimepicker(
        locale: moment.locale(),
        format: moment.localeData().longDateFormat('L'),
        extraFormats: ['YYYY-MM-DD']
      ).on 'dp.change', (e) ->
        debugger
        $altField.val e.date.format('YYYY-MM-DD')
      $(this).after($altField)
    )

    $('.datepicker-noalt').each(->
      $this = $(this)
      $(this).datetimepicker(
        locale: moment.locale(),
        format: 'DD/MM/YYYY',
        extraFormats: ['YYYY-MM-DD']
      ).on 'dp.change', (e) ->
      $(this)
    
    )
    

    $('.timepicker').each(->
      $this = $(this)
      $altField = $('<input type="hidden" />').attr('name', $this.attr('name')).val($this.val())
      $(this).datetimepicker(
        locale: moment.locale(),
        format: 'HH:mm',
        extraFormats: [moment.ISO_8601]
      ).on 'dp.change', (e) ->
        $altField.val e.date.format('HH:mm')
      $(this).after($altField)
    )

)(jQuery)


  

(($) ->
  jQuery ->
    return if $('.survey_sub_donut_chart').length == 0
    $('.survey_sub_donut_chart').each ->
      donut_id = @id
      $donut_id = $('#'+donut_id)
      if $donut_id.length == 1
        data = $donut_id.data('stats')
        options =
          animateScale: true
          responsive: true
          legendTemplate : '<div style="display: none;"></div>'
          percentageInnerCutout: 80
          onAnimationComplete: ->
            tooltipEl = $donut_id.prev('.survey_donut_text')
            total = 0
            current_data = null
            $(data).each (k,v) ->
              total += parseInt(this.value,10)
              current_data = this if this.value != 0
            tooltip_html =
            if donut_id == 'survey_donut_participants_chart'
              '<strong>'+current_data.label+'</strong><span>'+current_data.value+'</span>'
            else
              '<strong>'+current_data.label+'</strong><span>'+((current_data.value/total)*100).toFixed(2)+"%"+'</span>'
            tooltipEl.html(tooltip_html)
            tooltipEl.show()
            tooltipEl.css({
              opacity: 1,
              left: $donut_id[0].offsetLeft + $donut_id.height() - (tooltipEl.width() / 2) + 'px',
              top: $donut_id[0].offsetTop + ($donut_id.height() / 2) - (tooltipEl.height() / 2) + 'px'
            })
          customTooltips: (tooltip) ->
            tooltipEl = $donut_id.prev('.survey_donut_text')

            return unless tooltip

            # Set caret Position
            tooltipEl.removeClass('above below')
            tooltipEl.addClass(tooltip.yAlign)
            # Set Text
            text = tooltip.text.split(':')
            tooltip_html = if donut_id == 'survey_donut_participants_chart'
              '<strong>'+text[0]+'</strong><span>'+text[1]+'</span>'
            else
              total = 0
              $(data).each ->
                total += parseInt(this.value, 10)
              '<strong>'+text[0]+'</strong><span>'+((text[1]/total)*100).toFixed(2)+"%"+'</span>'
            tooltipEl.html(tooltip_html)

            # Display, position, and set styles for font
            tooltipEl.css({
              opacity: 1,
              left: tooltip.chart.canvas.offsetLeft + $donut_id.height() - (tooltipEl.width() / 2) + 'px',
              top: tooltip.chart.canvas.offsetTop + ($donut_id.height() / 2) - (tooltipEl.height() / 2) + 'px',
              fontFamily: tooltip.fontFamily,
              fontSize: tooltip.fontSize,
              fontStyle: tooltip.fontStyle
            })

        ctx = $donut_id.get(0).getContext("2d")
        doughnutChart = new Chart(ctx).Doughnut(data,options)
        $donut_id.before(doughnutChart.generateLegend())
        $donut_id.before('<div class="survey_donut_text donut-text"></div>')
)(jQuery)
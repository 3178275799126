(($) ->
  $(document).ready ->
    iconFormatState = (item) ->
      $element = $(item.element)
      $icon_url = $element.data('url')

      if !$icon_url
        item.text
      else
        $item = $(
          '<span><img src="' + $icon_url + '" class="icon" /> ' + item.text + '</span>'
        );
        $item
    
    $(".select2").select2
      theme: "bootstrap"
      width: null

    $(".select2.select2-icons").select2
      theme: "bootstrap"
      width: null
      containerCssClass: "select2-icons"
      templateResult: iconFormatState
      templateSelection: iconFormatState

)(jQuery)

(($) ->
  dashboard_module =
    init: ->

        dashboard_module.equal_heights()
        dashboard_module.widgets_action()

    equal_heights: ->

      $('.eq_heights').each ->
        $that = $(@)

        adjust_div_heights = ->
          max_height = 0
          $that.find('.eq_height').css('height', 'auto')
          $that.find('.eq_height').each ->
            current_height = $(@).height() - 15
            max_height = current_height if max_height < current_height
          $that.find('.eq_height').css('height', max_height+'px')




        $(window).on 'load', ->
          adjust_div_heights()

    widgets_action: ->
      max_occurence = 4
      aw_occurence = $('.widget-action').length
      while aw_occurence > max_occurence
        aw_occurence--
        $('.widget-action:last').remove()



  $(window).on "load", ->
    dashboard_module.init()


  $(window).resize ->
    if $(window).width() < 1500
      $('#graph').removeClass('col-md-8 eq_heights')
      $('#actions').removeClass('col-md-4')
      $('#graph').css({ "margin-bottom": '80px'})
      $('.mainpanel').css({ "padding-bottom": '500px'})
      return
    if $(window).width() > 1500
      $('#graph').addClass( "col-md-8" )
      $('#actions').addClass('col-md-4')
      $('.mainpanel').css({ "padding-bottom": '20px'})
      return
  return


)(jQuery)


(($) ->
  job_offers_module =
    init: ->
      if $('.controller-job_offers.action-index').length == 1
        job_offers_module.filemanager_equal_heights()
        job_offers_module.filemanager_options()
        job_offers_module.adapt_form_search_width()
  #    if $('.controller-job_offers.action-new, .controller-job_offers.action-create, .controller-job_offers.action-edit, .controller-job_offers.action-update').length == 1
  #       Do Nothing

    filemanager_equal_heights: ->
      adjust_document_heights = ->
        max_image_height = 0
        $('.filemanager .thmb-prev').css('height', 'auto')
        $('.filemanager img').removeAttr('width').removeAttr('height')
        $('.filemanager img').each ->
          current_image_height = $(@).height()
          max_image_height = current_image_height if max_image_height < current_image_height
        $('.filemanager .thmb-prev').height(max_image_height)

        max_document_height = 0
        $('.filemanager .thmb').css('height', 'auto')
        $('.filemanager .thmb').each ->
          current_document_height = $(@).outerHeight()
          max_document_height = current_document_height if max_document_height < current_document_height
        $('.filemanager .thmb').css('height', max_document_height+'px')

      $(window).resize ->
        adjust_document_heights()

      $(window).on 'load', ->
        adjust_document_heights()


    filemanager_options: ->
      show_thmb_options = ->
        $(@).find('.ckbox').show()
        $(@).find('.fm-group').show()
      hide_thmb_options = ->
        unless $(@).closest('.thmb').hasClass('checked')
          $(@).find('.ckbox').hide()
          $(@).find('.fm-group').hide()
      $('.thmb').on 'mouseover', show_thmb_options
      $('.thmb').on 'mouseout', hide_thmb_options


      $('.ckbox').each ->
        parent = $(@).parent();
        if $(@).find('input').is(':checked')
          $(@).show();
          parent.find('.fm-group').show();
          parent.addClass('checked');


      $('.ckbox').on 'click', (event) ->
        if !$(@).find('input').is(':checked')
          $(@).closest('.thmb').removeClass('checked')
          enable_itemopt(false)
        else
          $(@).closest('.thmb').addClass('checked')
          enable_itemopt(true)


      enable_itemopt = (enable) ->
        if enable
          $('.itemopt.bulkopt').removeClass('disabled')
          if $('.multiple-ckbox-news:checked').length == 1
            $('.itemopt.singleopt').removeClass('disabled')
          else
            $('.itemopt.singleopt').addClass('disabled')
        else
          # check all thumbs if no remaining checks
          # before we can disabled the options
          ch = false
          $('.thmb').each ->
            if $(@).hasClass('checked')
              ch = true

          if !ch
            $('.itemopt.bulkopt, .itemopt.singleopt').addClass('disabled')
          else if $('.multiple-ckbox-news:checked').length == 1
            $('.itemopt.singleopt').removeClass('disabled')


      $('#news-single-edit').on 'click', (event) ->
        event.preventDefault()
        return false if $(@).hasClass('disabled')
        params = ''
        $item = $('.multiple-ckbox-news:checked:first')
        window.location.href = $(@).attr('href').split('?')[0]+'/'+$item.val()+'/edit'


      $('#news-multiple-destroy').on 'click', (event) ->
        event.preventDefault()
        confirm_destroy_text = if $('.multiple-ckbox-news:checked').length > 1
          $(@).data('text-many')
        else
          $(@).data('text-one')
        return false if $(@).hasClass('disabled') || !confirm(confirm_destroy_text)
        params = ''
        $('.multiple-ckbox-news:checked').each ->
          params += '&ids[]='+$(@).val()
        window.location.href = $(@).attr('href')+params


    adapt_form_search_width: ->
      adjust_form_search_width = ->
        available_width = $('.filemanager-options').outerWidth()
        $('.filemanager-options li:not(.search-li)').each ->
          available_width -= $(@).outerWidth()
        available_width -= parseInt($('.search-li').css('padding-left'))
        available_width -= parseInt($('.search-li').css('padding-right'))
        $('.form-search').width(available_width)
      $(window).resize ->
        adjust_form_search_width()
      $(window).on "load", ->
        adjust_form_search_width()


  $(document).ready ->
    job_offers_module.init()
)(jQuery)
(function() {
  var HOST = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')
  var ROUTE = HOST + '/api/attachements'
  addEventListener("trix-attachment-add", function(event) {
    if (event.attachment.file) {
      uploadFileAttachment(event.attachment)
    }
  })

  function uploadFileAttachment(attachment) {
    uploadFile(attachment.file, setProgress, setAttributes)

    function setProgress(progress) {
      attachment.setUploadProgress(progress)
    }

    function setAttributes(attributes) {
      attachment.setAttributes(attributes)
    }
  }

  function uploadFile(file, progressCallback, successCallback) {
    var key = createStorageKey(file)
    var formData = createFormData(key, file)
    var xhr = new XMLHttpRequest()
    xhr.open("POST", ROUTE, true)
    xhr.setRequestHeader('Accept', 'application/version.v2')
    xhr.setRequestHeader('X-Authentication-Token', '3af7f3543b6350ca4f634be7c91bc1efc52cdb75')

    xhr.upload.addEventListener("progress", function(event) {
      var progress = event.loaded / event.total * 100
      progressCallback(progress)
    })

    xhr.addEventListener("load", function(event) {
      console.log(xhr.status)
      if (xhr.status == 200) {
        var attributes = {
          url: JSON.parse(xhr.responseText).url,
          href: JSON.parse(xhr.responseText).url
        }
        console.log(attributes)
        successCallback(attributes)
      }
    })

    xhr.send(formData)
  }

  function createStorageKey(file) {
    var date = new Date()
    var day = date.toISOString().slice(0,10)
    var name = date.getTime() + "-" + file.name
    return [ "tmp", day, name ].join("/")
  }

  function createFormData(key, file) {
    var data = new FormData()
    data.append("key", key)
    data.append("Content-Type", file.type)
    data.append("file", file)
    return data
  }
})();

(($) ->
  admins_module =
    init: ->
      if $('body.controller-admins.action-new, body.controller-admins.action-create, body.controller-admins.action-edit, body.controller-admins.action-update').length == 1
        admins_module.birthdate_mask()
        admins_module.mandate_date_mask()
        admins_module.access_fields()
        admins_module.load_more_admins()
  #    if $('body.controller-admins.action-edit, body.controller-admins.action-update').length == 1
  #      # Do Nothing


    birthdate_mask: ->
      $("#admin_birthdate").mask("99-99-9999")


    mandate_date_mask: ->
      $("#admin_mandate_date").mask("99-99-9999")


    access_fields: ->
      $('#access .access-right-field').each ->
        $input = $(@)
        $toggle = $(@).parent().find('.toggle')
        $toggle.toggles
          on: $(@).val() == 'true' || $(@).val() == '1' || $('#access').data('superadmin') == 1
          click: $('#access').data('editable') == 1
          drag: $('#access').data('editable') == 1
          text:
            on: 'ACTIVÉ'
            off: 'DÉSACTIVÉ'
        $toggle.on 'toggle', (e, active) ->
          if active
            $input.val('true')
          else
            $input.val('false')
        if $('#access').data('editable') != 1
          $toggle.addClass('disabled')


    load_more_admins: ->
      $('#load-more-activities').on 'ajax:success', (event, data, status, xhr) ->
        $container = $(@).prev('.activity-list')
        $container.append(data)
        new_offset = $container.find('.media').length
        if $('<div>').append(data).find('.media').length < 10
          $(@).hide()
        else
          $(@).attr('href', $(@).attr('href').replace(/offset=\d+/, 'offset='+new_offset))


  $(document).ready ->
    admins_module.init()
)(jQuery)

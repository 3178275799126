(($) ->
  $(window).on "load", ->
    unless $('.sortable-list').length == 0
      refresh_position_indexes = ->
        sortable_items_order = []
        $('.sortable-list .sortable-item').each (index, sortable_item) ->
          sortable_items_order.push $(sortable_item).data('id')
        $('#position_indexes').val(sortable_items_order.join(','))

      $('.sortable-list').sortable({
        update: (event, ui) ->
          refresh_position_indexes()
      }).disableSelection()
)(jQuery)
(($) ->
  users_module =
    init: ->
      if $('body.controller-users.action-new, body.controller-users.action-create, body.controller-users.action-edit, body.controller-users.action-update').length == 1
       # users_module.birthdate_mask()
       # users_module.mandate_date_mask()
        users_module.comment_textarea()
        users_module.form_saving_prompt()
  #    if $('body.controller-users.action-edit, body.controller-users.action-update').length == 1
        # Do Nothing


#    birthdate_mask: ->
#      $("#user_birthdate").mask("99-99-9999")


    experience_fields: ->
      $(document).on 'focus click', '#experiences .datepicker', (event) ->
        unless $(@).hasClass('hasDatepicker')
          $(@).datepicker({
            dateFormat: 'yy-mm-dd',
            autoclose: true
          })
          $(@).datepicker 'show'


    comment_textarea: ->
      $(window).on "load", ->
        resize_comment_textarea()
      $(window).resize ->
        resize_comment_textarea()

      resize_comment_textarea = ->
        $('.comment-textarea').each ->
          $row = $(@).parents('.row').first()
          total_width = $row.width()
          textarea_width = Math.round(total_width - $row.find('.pull-left').first().width() - 40)
          textarea_width = total_width - 40 if textarea_width < 200
          $(@).css 'width', textarea_width


    form_saving_prompt: ->
      form_warn_exit_before_save = 0
      confirm_exit = ->
        if form_warn_exit_before_save == 1
          "Vous allez quitter cette page sans apporter les modifications faites sur le profil."

      $('.user-form .add_fields, .user-form .remove_fields').on 'click', ->
        form_warn_exit_before_save = 1
      $('.user-form *').on 'change', ->
        form_warn_exit_before_save = 1
      window.onbeforeunload = confirm_exit
      $(".user-form").on 'submit', ->
        form_warn_exit_before_save = 0





  $(document).ready ->
    users_module.init()
)(jQuery)

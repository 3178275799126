(($) ->
  discussion_comment_module =
    init: ->
      if $('.controller-discussion_comments.action-index').length == 1
        discussion_comment_module.state_button()
      if $('.controller-discussion_comments.action-edit, .controller-discussion_comments.action-update').length == 1
        discussion_comment_module.checkboxes()


    checkboxes: ->
      $('#moderate_true').on 'change', (event) ->
        $('#moderate_false').trigger 'click'
      $('#moderate_false').on 'change', (event) ->
        $('#moderate_true').trigger 'click'


    state_button: ->
      $('.moderate-true').on 'click', (event) ->
        event.preventDefault()
        $td = $(@).parents('td').first()
        $(@).addClass('hidden')
        $td.find('.moderate-false').removeClass('hidden')
        $td.find('.moderate-dropdown .value').html('Modéré')
        $td.find('.moderate-dropdown').removeClass('btn-danger')
        $td.find('.moderate-dropdown').addClass('btn-success')
        $td.removeClass('open')

      $('.moderate-false').on 'click', (event) ->
        event.preventDefault()
        $td = $(@).parents('td').first()
        $(@).addClass('hidden')
        $td.find('.moderate-tdue').removeClass('hidden')
        $td.find('.moderate-dropdown .value').html('Non modéré')
        $td.find('.moderate-dropdown').addClass('btn-danger')
        $td.find('.moderate-dropdown').removeClass('btn-success')
        $td.removeClass('open')

  $(document).ready ->
    discussion_comment_module.init()
)(jQuery)

$('#maps-index-form').submit ->
  valuesToSubmit = $(this).attr('action') + '?' + $(this).serialize()
  console.log valuesToSubmit
  request = undefined
  request = $.ajax(url: valuesToSubmit)
  request.done (data, textStatus, jqXHR) ->
    console.log data
    jsonMarkers = data
    updateMap(jsonMarkers)
    updateAlert(jsonMarkers)
    return false
  request.error (jqXHR, textStatus, errorThrown) ->
    console.log 'AJAX Error:' + textStatus
    alert 'Une erreur s\'est produite'
    return false
  return false


# update map with new filters
updateMap = (jsonMarkers)->
  if jsonMarkers isnt null
    console.log(jsonMarkers)
    handler = Gmaps.build('Google')
    handler.buildMap { internal:  {id: 'map'} }, ->
      markers = handler.addMarkers(jsonMarkers)
      handler.bounds.extendWith markers
      if townCoordinates.length == 2
        handler.map.centerOn( [ townCoordinates[0], townCoordinates[1] ] )
        handler.getMap().setZoom(12)
      else
        handler.fitMapToBounds
      return
  else
    handler = Gmaps.build('Google')
    handler.buildMap({ internal: {id: 'map' }})
    handler.map.centerOn( [ townCoordinates[0], townCoordinates[1] ] )
    handler.getMap().setZoom(12)

# update alert count
updateAlert = (jsonMarkers) ->
  if jsonMarkers isnt null and jsonMarkers.length > 0
    $( "div.panel-btns" ).text( jsonMarkers.length + ' signalements trouvés'  )
    $( "div.alert-panel" ).addClass('alert-success').removeClass('alert-danger')
  else
    $( "div.panel-btns" ).text( 'Aucun signalement n\'a été trouvé. Veuillez recommencer votre recherche.' )
    $( "div.alert-panel" ).addClass('alert-danger').removeClass('alert-success')

# initialize map with default filters
loadMap = ->
  if  jsonMarkersCount?
    if jsonMarkersCount > 0
      handler = Gmaps.build('Google')
      handler.buildMap { internal:  {id: 'map'} }, ->
        markers = handler.addMarkers(jsonMarkers)
        handler.bounds.extendWith markers
        if townCoordinates.length == 2
          handler.map.centerOn( [ townCoordinates[0], townCoordinates[1] ] )
          handler.getMap().setZoom(12)
        else
          handler.fitMapToBounds
        return
    else
      handler = Gmaps.build('Google')
      handler.buildMap({ internal: {id: 'map' }})
      handler.map.centerOn( [ townCoordinates[0], townCoordinates[1] ] )
      handler.getMap().setZoom(12)

$(document).on 'ready page:load', ->
  loadMap()



(($) ->
  surveys_module =
    init: ->
      if $('.controller-surveys.action-edit, .controller-surveys.action-update').length == 1
        surveys_module.dynamic_state()
        surveys_module.survey_question_count()


    dynamic_state: ->
      $container = $('#select-published-container')
      $selected = $container.find('option:selected')

      update_save_btn_wording = ->
        if $('#survey_start_date').val().length != 0 && $('#survey_end_date').val().length != 0 && $selected.val() == 'true' && Date.parse($('#survey_start_date').val()) <= Date.parse($container.data('today')) && Date.parse($('#survey_end_date').val()) >= Date.parse($container.data('today'))
          $('#save-survey-btn').text $('#save-survey-btn').data('wording-published')
        else if $selected.val() == 'true'
          $('#save-survey-btn').text $('#save-survey-btn').data('wording-pending')
        else
          $('#save-survey-btn').text $('#save-survey-btn').data('wording-draft')


      $('#edit-survey-state').on 'click', (event) ->
        event.preventDefault()
        $container.toggleClass('hidden')
        unless $container.hasClass('hidden')
          # open select
          if (document.createEvent)
            e = document.createEvent("MouseEvents");
            e.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            $('#survey_active')[0].dispatchEvent(e);
          else if ($('#survey_active').fireEvent)
            $('#survey_active')[0].fireEvent("onmousedown");


      $('#survey_active').on 'change', (event) ->
        $selected = $('#select-published-container option:selected')
        $('#survey-visual-state').text $selected.text()
        update_save_btn_wording()
        $('#select-published-container').addClass('hidden')


    survey_question_count: ->
      update_survey_question_count = ->
        $('#survey_question_count').html($('#survey_question').val().length + ' / 255 ')

      $('#survey_question').on 'keyup', (event) ->
        update_survey_question_count()

      update_survey_question_count()

  $(document).ready ->
    surveys_module.init()
)(jQuery)
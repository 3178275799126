groupsToSuggest = 0
suggestedGroups = 0
featureId = undefined
alertsIdToAdd= []
alertsIdToRemove = []
alertStartingDate = undefined
alertEndingDate = undefined
updatedAlerts = 0
alerts = []

$(document).ready ->
  if $('#alert-index-filter').length && location.search.substring(1).split("&").length <= 1
    checkForModal()


$('#group-modal').on 'hide.bs.modal', ->
  if $('#alert-index-filter').length
    checkForModal()

# Check if some suggested groups are found
checkForModal = () ->
  unHighlightAlerts(alerts)
  featureId = $('#alert-show-form').attr('class') || $('#feature_id').val()
  $('div.alert.alert-panel.alert-group-creation').addClass('hidden').removeClass('alert-success alert-danger')
  url = $('#alert-index-filter').attr('action') + '?feature_id=' + featureId
  request = undefined
  request = $.ajax(url: url, cache: false, dataType: 'json')
  request.done (data, textStatus, jqXHR) ->
    if data.suggested_groups?
      groupsToSuggest = data.suggested_groups.length
      if suggestedGroups < groupsToSuggest
        alerts = []
        data.suggested_groups[(suggestedGroups)].forEach (alert) ->
          alerts.push(alert.id)
        highlightAlerts(alerts)
        suggestedGroups++
        $('#group-modal').modal('show')
        $('div.h4.col-xs-12').text('Nous avons trouvé des alertes pouvant être similaires. Afin de les regrouper il vous suffit de les sélectionner puis de valider votre formulaire.')
        $( ".group-suggestions-counter").addClass('h4 text-center').text( 'Suggestion ' +  suggestedGroups + '/' + groupsToSuggest )
      else
        $('#group-modal').remove()
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        return false
    else
      $('#group-modal').remove()
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      return false


$('.btn-group-management').click ->
  currentAlertId = $('.alert-id').attr('id')
  $('.alert-thumbnail#' + currentAlertId + '').appendTo('.group-alerts')
  $('.alert-thumbnail#' + currentAlertId + '').find('span.btn').addClass('btn-warning remove-from-group').removeClass('btn-success add-to-group').text('Retirer')
  featureId = $('#alert-show-form').attr('class') || $('#feature_id').val()
  $('#group-modal').modal('show')
  $( ".group-suggestions-counter").addClass('h2 text-center').text( 'Gestion du groupe' )
  $('.group-alerts').children().each (alert) ->
    alertsIdToAdd.push($(this).attr('id'))
    console.log alertsIdToAdd + 'alert to add'


highlightAlerts = (alerts) ->
  alerts.forEach (alert) ->
    $('.alert-thumbnail#' + alert + '').addClass('alert-success').prependTo('.index-alerts')

unHighlightAlerts = (previousAlerts) ->
  $('.group-alerts').children().remove()
  previousAlerts.forEach (previousAlert) ->
    $('.alert-thumbnail#' + previousAlert + '').removeClass('alert-success')

# Increment AddAlerts array which is going to be submitted for group creation
$(document).on 'click', '.add-to-group', ->
  alertThumbnail = $(this).parent()
  console.log  alertThumbnail
  alertId = alertThumbnail.attr('id')
  alertsIdToAdd.push(alertId)
  index = $.inArray alertId, alertsIdToRemove
  alertsIdToRemove.splice(index, 1)
  alertThumbnail.appendTo('.group-alerts')
  alertThumbnail.find( 'span.btn' ).text( 'Retirer'  )
  alertThumbnail.find( 'span.btn' ).addClass('remove-from-group btn-warning').removeClass('add-to-group btn-success')
  console.log 'alerts to remove ' + alertsIdToRemove
  console.log 'alerts to add ' + alertsIdToAdd

# Decrement AddAlerts array which is going to be submitted for group creation
$(document).on 'click', '.remove-from-group', ->
  alertThumbnail = $(this).parent()
  alertId = alertThumbnail.attr('id')
  alertsIdToRemove.push(alertId)
  index = $.inArray alertId, alertsIdToAdd
  alertsIdToAdd.splice(index, 1)
  alertThumbnail.prependTo('.index-alerts')
  alertThumbnail.find( 'span.btn' ).text( 'Ajouter')
  alertThumbnail.find( 'span.btn' ).addClass('add-to-group btn-success').removeClass('remove-from-group btn-warning')
  console.log 'alerts to add ' + alertsIdToAdd
  console.log 'alerts to remove ' + alertsIdToRemove


# For group creation submission
$('.group-form-create').submit ->
  alertsToSubmit = alertsIdToAdd
  if alertsToSubmit.length <= 1
    alert('Vous devez selectionner au moins deux alertes afin de former un groupe')
    location.reload()
  request = undefined
  url = $(this).attr('action')
  valueToSubmit =  { "alerts_id": alertsToSubmit , "group": { kind:"alert"} }
  request = $.ajax(url: url, type: 'POST', data:  valueToSubmit, dataType: 'json')
  request.done (data, textStatus, jqXHR) ->
    alertsIdToAdd= []
    if data.status == 200
      $('div.alert.alert-panel.alert-group-creation').addClass('alert-success').removeClass('hidden ').text( 'Votre groupe a bien été créé.' )
      if $('#alert-index-filter').length
        setTimeout( checkForModal , 2000)
      else
        setTimeout(location.reload(), 2000)
    else
      $('div.alert.alert-panel.alert-group-creation').addClass('alert-danger').removeClass('hidden').text( 'Votre groupe n\' pas été créé')
      setTimeout( checkForModal , 2000)
    return false
  return false


$('.group-form-update').submit ->
  groupId =  $('.group-form-update').attr('id')
  updateAlertsGroup (groupId)
  return false

# Add alerts to an existing group
updateAlertsGroup  = (groupId) ->
  if alertsIdToAdd.length >= 1
    alertsIdToAdd.forEach (alertId) ->
      url = '/admin/alerts/' + alertId + '?feature_id=' + featureId
      valueToSubmit =   { "alert": {"group_id": groupId} }
      request = $.ajax(url: url, type: 'PATCH', data:  valueToSubmit, cache: false)
      request.done (data, textStatus, jqXHR) ->
        if data.status == 200
          updatedAlerts++
          if alertsIdToAdd.length + alertsIdToRemove.length == updatedAlerts
            $('div.alert.alert-panel.alert-group-creation').addClass('alert-success').removeClass('hidden ').text( 'Votre groupe a bien été modifié' )
            $('#group-modal').modal('show')
            alertsIdToAdd= []
            alertsIdToRemove = []
            setTimeout( location.reload() , 2000)
        else
          alert('erreur lors de la mise à jour de la catégorie')
          return false
  if alertsIdToRemove.length >= 1
    alertsIdToRemove.forEach (alertId) ->
      url = '/admin/alerts/' + alertId + '?feature_id=' + featureId
      valueToSubmit =   { "alert": {"group_id": null} }
      request = $.ajax(url: url, type: 'PATCH', data:  valueToSubmit, cache: false)
      request.done (data, textStatus, jqXHR) ->
        if data.status == 200
          updatedAlerts++
          if alertsIdToAdd.length + alertsIdToRemove.length == updatedAlerts
            $('div.alert.alert-panel.alert-group-creation').addClass('alert-success').removeClass('hidden ').text( 'Votre groupe a bien été modifié' )
            $('#group-modal').modal('show')
            alertsIdToAdd= []
            alertsIdToRemove = []
            setTimeout( location.reload(), 2000)
        else
          alert('erreur lors de la mise à jour de la catégorie')
          return false




$('.datepicker-with-event-listener').each(->
  $this = $(this)
  $(this).datetimepicker(
    locale: moment.locale(),
    format: moment.localeData().longDateFormat('L'),
    extraFormats: ['YYYY-MM-DD']
  ).on 'dp.change', (e) ->
    if $(this).attr('id') == 'starting_date_'
      alertStartingDate = [$(this).val()]
    else if $(this).attr('id') == 'ending_date_'
      alertEndingDate =  [$(this).val()]
      if alertEndingDate? && alertStartingDate?
        $('.alerts-group-filter').change()
)



# Filter alerts displayed on modal
$('.alerts-group-filter').on 'change', ->
  console.log alertStartingDate
  url = $(this).attr('action') + '&' + $(this).serialize()
  request = undefined
  console.log url
  request = $.ajax(url: url, cache: false, dataType: 'json')
  request.done (data, textStatus, jqXHR) ->
    $('.add-to-group').parent().hide()
    if data?
      console.log data
      displayedAlertsId = []
      data.alerts.forEach (alertObject) ->
        displayedAlertsId.push(alertObject.id)
        $('.alert-thumbnail#' + alertObject.id+ '').show()
    return false
  request.error (jqXHR, textStatus, errorThrown) ->
    console.log 'AJAX Error:' + textStatus
    alert 'Une erreur s\'est produite'
    return false
  return false

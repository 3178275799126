(($) ->
  discussion_document_module =
    init: ->
      if $('.controller-discussion_documents.action-index, .controller-discussion_documents.action-edit, .controller-discussion_documents.action-update').length == 1
        discussion_document_module.dropzone()


    dropzone: ->
      # File upload
      Dropzone.autoDiscover = false
      _method = if $('.controller-discussion_documents.action-index').length == 1
        'post'
      else
        'put'
      $("#file_upload").dropzone
        maxFilesize: 20
        paramName: "discussion_document[file]"
        params:
          '_method': _method
          authenticity_token: $('meta[name=csrf-token]').attr('content')
          'discussion_document[dropzone]': 1
        init: ->
          if $('#existing-image').length == 1
            mockFile =
              name: ''
              size: $('#existing-image').data('size')
              type: 'image/jpeg'
              status: Dropzone.ADDED
              url: $('#existing-image').data('url')
            # Call the default addedfile event handler
            this.emit("addedfile", mockFile)
            # And optionally show the thumbnail of the file:
            this.emit("thumbnail", mockFile, $('#existing-image').data('url'))
            this.files.push(mockFile)

          this.on "success", (file, response) ->
            if $('.controller-discussion_documents.action-index').length == 1 && $('.discussion_document-form').attr('action') != response.path
              # change form url
              $('.discussion_document-form').attr('action', response.path)
              $('.discussion_document-form').append('<input type="hidden" name="_method" value="put" />')
              $('.discussion_document-form').append('<input type="hidden" name="from_create" value="true" />')
              # change dropzone upload url
              this.options.url = response.path
              this.options.params._method = 'put'

        complete: ->
          that = this
          if that.files.length > 1
            for i in [0..that.files.length-2]
              that.removeFile(that.files[i])


  $(document).ready ->
    discussion_document_module.init()
)(jQuery)
(($) ->
  $(document).ready ->
    $('.colorpicker-input').each ->
      $colorPicker = $(@)
      $colorSelector = $(@).next('.colorselector')
      #console.log($colorSelector.find('span').first())
      $colorSelectorBg = $colorSelector.find('span').first()
      #$module_item = $(@).parents('.module-container').find('.module-item')
      if $colorPicker.is(':disabled')
        $colorSelector.hide()
      else
        $colorSelectorBg.css('backgroundColor', $colorPicker.val())
        $colorSelector.ColorPicker
          onShow: (colpkr) ->
            $(colpkr).fadeIn(500)
            false
          onHide: (colpkr) ->
            $(colpkr).fadeOut(500)
            false
          onChange: (hsb, hex, rgb) ->
            $colorSelectorBg.css('backgroundColor', '#' + hex)
            $colorPicker.val('#'+hex)
            #$module_item.css('background-color', '#' + hex)
)(jQuery)

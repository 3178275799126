(($) ->
  $('.preloader-feedback').on 'click', ->
    $('#preloader').css({display: 'block'})
    $('#preloader .text').html('Veuillez Patienter...')

  $('textarea').each ->
    $this = $(@)
    $maxlength = $this.attr('maxlength')
    if $maxlength
      $maxlength_display = $('<div class="pull-right"></div>')
      $maxlength_display.html($this.val().length + ' / ' + $maxlength)
      refresh_maxlength = () ->
        $maxlength_display.html($this.val().length + ' / ' + $maxlength)

      $this.on 'keyup', refresh_maxlength
      refresh_maxlength()
      $this.after($maxlength_display)

  $('input[data-role="tagsinput"]').each ->
    $(@).tagsInput
      width: '100%',
      defaultText: '+'
)(jQuery)
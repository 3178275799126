(($) ->
  $(document).ready ->
    $('.dataTable th.sorting, .dataTable th.sorting_asc, .dataTable th.sorting_desc').on 'click', (event) ->
      if $(event.target).is('a')
        event.preventDefault()
        window.location.href = $(event.target).attr('href')
      else if $(@).find('a').length == 1
        $(@).find('a').trigger 'click'
    $('.dataTable tr').each ->
      if $(@).find('a.show_item').length == 1
        $(@).on 'click', (event) ->
          unless $(event.target).is('.table-action') || $(event.target).parents().is('.table-action')
            window.location = $(@).find('a.show_item').attr('href')
)(jQuery)